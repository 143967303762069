import { GatsbyBrowser } from 'gatsby'
import './src/styles/global.css'
import { createRoot } from 'react-dom/client'
import { Renderer } from 'react-dom'

export const replaceHydrateFunction: GatsbyBrowser['replaceHydrateFunction'] = (
  _args,
  _options
) => {
  return ((element, container) => {
    const root = createRoot(container)
    root.render(element)
  }) as Renderer
}
